.addMoneyContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;
  gap: 40px;
}

.ctaButtonWrapper {
  cursor: pointer;
  width: 50%;
  border: 1px solid var(--gray-800);
  background-color: var(--gray-800);
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;
  border-radius: 50px;
  padding: 15px 20px;
  & span {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    padding: 10px 10px;
    & span {
      font-size: 1rem;
    }
  }
}

.qrButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--violet-primary);
  border-radius: 50px;
  height: 70px;
  width: 70px;

  /* Centering adjustments */
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 430px) {
    height: 60px;
    width: 60px;
  }
}

/* dashboard.module.css */
.circularIconWrapper {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  border: 1px solid var(--gray-700);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-800); /* or any color you prefer */
}
