/* Modal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other content */
}

.modalContent {
  position: fixed; /* Make modal fixed */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */
  background: #171717; /* Dark background for modal */
  color: #fff; /* White text */
  padding: 30px;
  border-radius: 12px; /* Rounded corners */
  width: 700px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust shadow */
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 600px) {
  .modalContent {
    max-width: 100%;
    width: 100%; /* Full width on mobile */
    border-radius: 20px; /* Remove border-radius for full width */
    top: auto; /* Remove top positioning */
    bottom: 0; /* Stick to bottom */
    left: 0; /* Align to the left */
    transform: none; /* Remove transform for full width */
  }
}

.modalContent h2 {
  margin-bottom: 30px;
}

.modalContent button {
  margin-top: 20px;
  padding: 15px;
  background: var(--yellow-primary);
  color: var(--black-primary);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s;
  width: 100%;
  font-size: 1.125rem; /* 18px */
}

.modalContent button:hover {
  background: var(--gray-600);
  color: white;
}

.networkList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.tokenLogoBox {
  position: relative;
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.tokenLogo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.networkLogo {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -10px;
  border: 1px solid rgb(14, 14, 14);
  background-color: var(--white);
}

.actionIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  position: absolute;
  bottom: -4px;
  right: -10px;
  border: 1px solid var(--black-primary);
  background-color: var(--gray-800);
  color: var(--primary-yellow) !important;
}

.detailsContainer {
  width: 100%;
  background-color: var(--gray-800);
  padding: 20px;
  border-radius: 20px;
}

.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
}

.label {
  font-size: 1.125rem; /* 18px */
}
.value {
  font-size: 1.125rem; /* 18px */
}

.viewButtonContainer {
  margin-top: 15px;
}

.viewButton {
  font-size: 1.125rem; /* 18px */
  color: #fcff61 !important;
}

.detailsHeader {
  font-size: 1.125rem; /* 18px */
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  color: var(--gray-400);
}
