.app {
  display: grid;
  grid-template-columns: var(--sidebar-width) auto;
  width: 100dvw;
  min-height: 100dvh;
  @media (max-width: 720px) {
    display: flex;
    flex-direction: column;
  }
}

main {
  flex: 1;
  height: 100%;
  width: 100%;
}

.announcement {
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  min-height: var(--announcement-height);
}

.installPwa {
  /* border: 1px solid red; */
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  & > p {
    /* border: 1px solid blue; */
    text-align: center;
    width: 90%;
    opacity: 0.8;
    font-size: 18px;
  }
}

.instructionContainer {
  /* border: 1px solid green; */
  max-width: 98%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  & > img {
    border-radius: 6px;
  }
  & > span {
    color: var(--gray);
    margin-bottom: 8px;
  }
}

.divider {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #303030;
  height: 2px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
