.pageOverrides {
  height: calc(100dvh - var(--topbar-height)) !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.page::-webkit-scrollbar {
  display: none;
}

.page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  height: calc(100dvh - var(--topbar-height));
  background: var(--gray-900);
  color: var(--gray-50);
  padding: 48px 24px;
  font-size: 1rem;
  /* border: 1px solid red; */
  overflow: hidden;

  @media (max-width: 720px) {
    font-size: 0.8rem;
    padding: 24px;
  }

  & h1 {
    /* font-size: 2.625rem; */
    font-weight: bold;
    margin-bottom: 0.48em;
  }
  & h4 {
    font-size: 1.5rem;
    color: var(--gray-400);
  }
  & h5 {
    font-size: 1rem;
    font-weight: 400;
    color: var(--gray-400);
  }
  & h6 {
    font-size: 0.75rem;
    color: var(--gray-400);
    font-weight: 400;
    letter-spacing: 0.1ch;
  }
}
.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 720px) {
    max-width: 480px;
  }
}

.selectedCard {
  border: 2px solid #007bff !important;
}
.apyDisplay {
  text-align: left;
  padding: 12px;
}

.bigAPY {
  font-size: 48px;
  font-weight: bold;
}
.swapCard {
  padding: 8px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--gray-800);
  width: 100%;
  @media (max-width: 720px) {
    flex: 1; /* Take up all available space */
  }
  @media (min-width: 720px) {
    max-height: 70vh;
    /* overflow-y: auto; */
  }
}

.swapInputSymbol {
  font-size: 36px;
  color: var(--gray-500);
}

.swapBalanceText {
  margin-top: 0px;
  color: var(--gray-500);
}

.swapInputWrapper {
  background-color: var(--gray-900);
  border-radius: 12px;
  padding: 16px;
  transition: all 300ms ease-in-out;
  border: 1px solid var(--gray-900);
  &:hover {
    border-color: var(--gray-700);
  }
  & label {
    display: block;
    font-size: 14px;
    color: var(--gray-500);
    margin: 4px 0;
  }
  & small {
    display: inline-block;
    font-size: 14px;
    color: var(--gray-500);
    margin-top: 4px;
  }
}
.swapInput {
  all: unset;
  font-size: 30px;
  color: var(--gray-50);
  min-width: 0;
  flex: 1;
}
.switchButton {
  border-radius: 12px;
  height: 40px;
  width: 40px;
  margin: -18px auto;
  position: relative;
  background-color: var(--gray-800);
  & button {
    all: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  transition: all 300ms ease-in-out;
  border: 1px solid var(--gray-900);
  &:hover {
    border-color: var(--gray-700);
  }
}

.roundedIconButton {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: var(--primary);
}
.balancesTableWrapper {
  /* border: 1px solid red; */
  padding-left: 0; /* Remove left padding if it exists */
  background: var(--gray-800);
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%;
  border-radius: 20px;
  flex-grow: 1;
  padding: 10px;
  & > table {
    border-collapse: collapse;
    width: 100%;
    & tbody tr.yieldRow:not(:first-child) {
      /* border-top: 1px solid var(--gray-700); */
    }
    & tr.subYieldRow td {
      padding: 6px 8px;
      opacity: 0.7;
    }
    & td {
      padding: 12px 8px; /* Reduced from 24px to 12px */
      &:last-child {
        text-align: right;
      }
    }
  }
}

.cashTableWrapper {
  /* border: 1px solid red; */
  padding-left: 0; /* Remove left padding if it exists */
  background: var(--gray-800);
  overflow-y: scroll;
  scrollbar-width: none;
  border-radius: 20px;
  padding: 10px;
  & > table {
    border-collapse: collapse;
    width: 100%;
    & tbody tr.yieldRow:not(:first-child) {
      /* border-top: 1px solid var(--gray-700); */
    }
    & tr.subYieldRow td {
      padding: 6px 8px;
      opacity: 0.7;
    }
    & td {
      padding: 12px 8px; /* Reduced from 24px to 12px */
      &:last-child {
        text-align: right;
      }
    }
  }
}

.supplyTxnOvw {
  padding: 8px;
  & > h6 {
    margin-bottom: 8px;
  }
}

.supplyTxnOvwCard {
  border: 2px solid var(--gray-700);
  padding: 8px;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 8px;
  & > h6 {
    font-weight: 500;
    font-size: 0.625rem;
  }
  &:hover {
    opacity: 0.7;
  }
  & > img {
    width: 36px;
  }
}

.disconnectedAccount {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 20px;
  color: white;
}

.disconnectedAccount svg {
  margin-bottom: 15px;
  color: #888;
}

.disconnectedAccount p {
  font-size: 16px;
  color: #888;
}

.noBalances {
  /* border: 1px solid red; */
  height: 80%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
  text-align: center;
  /* border: 1px solid var(--gray-600);
  border-radius: 20px;
  background-color: var(--gray-900); */

  & > div {
    margin-top: 10px;
    width: 80%;
  }

  & > .iconContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  }

  & > button {
    padding: 10px;
    color: white;
  }
}

.depositButton {
  background-color: #007bff; /* Change button color to blue */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.depositButton:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.overviewContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.overviewViewAll {
  color: var(--primary) !important;
  cursor: pointer !important;
}

.overviewViewAll:hover {
  opacity: 0.8;
}

.filteredOppsContainer {
  overflow-y: scroll;
}

/* .swapCardContainer {
	display: flex;
	flex-direction: row;
	gap: 12px;
} */

.ctaContainer {
  display: flex;
  gap: 20px;
}

.ctaButtonWrapper {
  cursor: pointer;
  width: 50%;
  border: 1px solid var(--gray-700);
  background-color: var(--gray-800);
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 20px;
  opacity: 0.8;

  & .leftSection {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.2rem;
  }
  &:hover {
    opacity: 1;
  }
}

.pillContainer {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 20px;
  padding: 0;
}

.pillContainer2 {
  display: flex;
  /* justify-content: center; */
  margin-bottom: 20px;
  padding: 0;
  /* border: 1px solid red; */
  justify-content: space-between;
}

.pill {
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid #262e52;
  background-color: #1f212b;
  color: #ccc;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pill2 {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  width: 23%;
  border: 1px solid #262e52;
  background-color: #1f212b;
  color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
}

.activePill {
  background-color: var(--primary);
  color: white;
  border-color: #007bff;
}

.yieldRow {
  padding: 16px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* display: block; */
  border-radius: 8px !important;
}

.yieldRow:hover {
  background-color: var(--gray-800);
}

.breadcrumbs {
  & a {
    color: var(--primary) !important;
    text-decoration: none !important;
  }
  color: lightgray !important;
  margin-bottom: 10px;
}

.yieldDetailsParentContainer {
  background-color: var(--gray-800);
  padding: 1rem !important;
  border-radius: 10px;
}

.yieldDetailsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & div {
    align-items: center;
    & img {
      margin-right: 10px;
    }
    &:first-child {
      display: flex;
      flex-direction: row;
    }
  }
}

.transactionCard {
  background-color: var(--gray-800);
  padding: 1rem;
  border-radius: 20px;
  margin-bottom: 10px;
}

.networkTime {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.txnCardNetworkContainer {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between;
}

.txnCardHeading {
  display: flex;
  justify-content: space-between;

  & > * {
    margin-top: 10px;
  }

  @media (max-width: 480px) {
    & > h3 {
      font-size: 0.9rem;
    }
  }
}

.explorerLink:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.basicTokenDetails {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.priceDataContainer {
  /* min-height: 40vh; */
  /* border: 1px solid red; */
  margin-top: 20px;
}

.chartContainer {
  /* min-height: 40vh; */
  /* height: 30vh; */
  /* border: 1px solid red; */
  & > div > canvas {
    /* height: 30vh; */
    width: 100% !important;
  }
}

.timeFrameContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  justify-content: center;
}

.timeFrameOption {
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 4px 15px 4px 15px;
}

.actionContainer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: space-between;
  gap: 10px;

  & > button {
    font-size: 1.2rem;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    color: black;
    background: var(--yellow-primary);
    border: none;
    border-radius: 20px;
    border: 1px solid var(--gray-700);
    padding: 15px;
    transition: all 0.2s ease-in-out;

    @media (hover: hover) {
      &:hover {
        background: var(--gray-800);
        color: white;
        border: 1px solid var(--gray-700);
      }
    }
  }
}

.activeTimeFrame {
  background-color: var(--yellow-primary);
  color: black;
  border-radius: 20px;
}

.delta {
  font-size: 0.8rem;
}

.gain {
  color: #4dd15b;
}

.spotLightGain {
  color: #4dd15b;
}

.loss {
  color: #ff4824;
}

.networkContainer {
  margin-top: 10px;
  margin-bottom: 15px;
}

.networkLogoContainer {
  display: flex;
  gap: 4px;
  margin-top: 4px;
  margin-bottom: 100px;
}

.tokenExtensions {
  /* border: 1px solid red; */
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  & > a > div {
    text-decoration: none;
    color: lightgray !important;
    background: var(--gray-800);
    border: 1px solid var(--gray-700);
    padding: 8px;
    text-align: center;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.statsContainer {
  margin-top: 20px;
}

.statsCardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.statsCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  color: white;
  width: 100%;
}

.icon {
  /* Add icon styles if needed */
}

.linksContainer {
  display: flex;
  gap: 10px;
}

.linkButton {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: var(--gray-800);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px;
  cursor: pointer;
}

.linkButtonIcon {
  width: 20px;
  height: 20px;
}

.tokenInfo {
  padding-left: 10px;
}

.tokenInfo h3 {
  font-size: 0.9rem; /* Slightly reduced from 1rem */
  margin-bottom: 2px; /* Reduced from 4px */
}

.tokenInfo h5 {
  font-size: 0.75rem; /* Slightly reduced from 0.8rem */
  color: var(--gray-400);
}

.priceInfo {
  text-align: right;
  padding-right: 0; /* Remove right padding */
  white-space: nowrap;
}

.tokenPrice {
  font-size: 1rem; /* Slightly reduced from 1.2rem */
  font-weight: bold;
  display: block;
  margin-bottom: 2px; /* Reduced from 4px */
}

.delta {
  font-size: 0.8rem; /* Slightly reduced from 0.9rem */
  display: inline-block;
}

.gain {
  color: #4dd15b;
}

.loss {
  color: #ff4824;
}

@media (max-width: 480px) {
  .tokenInfo h3 {
    font-size: 1.1rem; /* Further reduced for mobile */
  }

  .tokenInfo h5 {
    font-size: 0.65rem; /* Further reduced for mobile */
  }

  .tokenPrice {
    font-size: 1.1rem; /* Further reduced for mobile */
  }

  .delta {
    font-size: 0.8rem; /* Further reduced for mobile */
  }
}

.logoCell {
  width: 40px; /* Set a fixed width for the logo column */
  padding-left: 0; /* Remove left padding */
}

.logoWrapper {
  width: 48px; /* Adjust this value as needed */
  height: 48px; /* Should be the same as width */
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: white; */
}

.tokenLogo {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the image covers the entire space */
}

/* Add these new styles */
.exploreHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.viewSelect {
  min-width: 120px;
  color: white !important;
}

/* Style the dropdown to match your theme */
.viewSelect .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.3);
}

.viewSelect:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.5);
}

.viewSelect.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.viewSelect .MuiSelect-icon {
  color: white;
}

.indexCell {
  /* padding-right: 10px; */
  padding: 0 !important;
  text-align: center;
  color: #888;
  font-size: 0.9em;
  width: 20px; /* Set a fixed width for the index column */
  /* border: 1px solid red; */
}

.portfolioValueContainer {
  padding: 20px;
  background-color: var(--yellow-primary);
  border-radius: 20px;
  margin-bottom: 20px;
  color: var(--black-primary);
}

.dashboardCard1 {
  padding: 20px;
  background-color: var(--violet-primary);
  border-radius: 20px;
  margin-bottom: 20px;
  & > h1 {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
}

.moneyBagContainer {
  width: 100px;
  height: 100px;
  & > img {
    width: 100%;
    height: 100%;
  }
}

.exploreSearchContainer {
  display: flex;
  padding: 10px;
  /* background-color: var(--gray-800);
	border: 2px solid var(--gray-700); */
  border: 2px solid var(--gray-700);
  background-color: var(--gray-800);
  border-radius: 30px;
  /* margin-bottom: 8px; */
  color: lightgray;
  flex-grow: 1;

  @media (max-width: 480px) {
    max-width: 100%;
  }

  & > input {
    display: flex;
    flex-grow: 1;

    @media (max-width: 480px) {
      max-width: 70%;
    }

    background: none;
    border: none;
    color: lightgray;
    margin-left: 10px;
    font-size: 1.2rem;
    outline: none;
  }

  &:hover {
    border: 2px solid var(--yellow-primary);
  }
}

.exploreOptionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  gap: 80px;

  margin-bottom: 10px;

  @media (max-width: 480px) {
    gap: 16px;
  }
}

.onRampComingSoon {
  /* border: 1px solid red; */
  height: 400px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gray;
  text-align: center;

  & > p {
    margin-top: 10px;
    width: 60%;
  }

  & > .iconContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 10px;
  }
}

.warning {
  color: #ff4824;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  border: 1px solid #ff4824;
  background-color: #ff482410;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.spotlightHeadingDiv {
  margin-top: 10px;
  display: flex;
  gap: 8px;
}

.spotlightCard {
  margin: 10px 0 10px 0px;
  border-radius: 20px;
  padding: 30px;
  background-color: var(--gray-800);
  color: white;
  border: 1px solid var(--gray-700);

  /* background-color: #1f212b; */
}

.cardSection {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  justify-content: space-between;
}

.spotlightCardTextContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.spotlightCardText {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.topGainersHeadingDiv {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  gap: 8px;
}

.trendingHeadingDiv {
  align-items: center;
  margin-top: 15px;
  display: flex;
  gap: 8px;
}
.chipContainer {
  display: flex;
  overflow-x: auto;
  padding: 5px 0;
  gap: 10px;
  cursor: grab;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.chip {
  display: flex;
  align-items: center;
  border: 1px solid var(--gray-700);
  padding: 20px;

  border-radius: 20px;
  white-space: nowrap;
  background-color: var(--gray-800);
}

.chipLogo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
}

.chipGain {
  color: #4caf50;
  margin-left: 8px;
}

.tokenLogo {
  width: 50px; /* Adjust the width as needed */
  height: 50px; /* Adjust the height as needed */
  border-radius: 50%; /* Keep the circular shape */
}

.tokenPriceSpotlight {
  font-size: 1.2rem; /* Slightly reduced from 1.2rem */
  font-weight: bold;
  display: block;
  margin-bottom: 2px;
}

.swapContainer {
  background-color: #1a1a1a;
  color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  margin: auto;
}

.amountDisplay {
  font-size: 2em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.priceDisplay {
  font-size: 1em;
  color: #888;
  margin-bottom: 20px;
}

.swapDetails {
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 10px;
}

.swapRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.swapArrow {
  text-align: center;
  margin: 10px 0;
}

.maxButton {
  all: unset;
  cursor: pointer;
  font-size: 1rem;
  color: white;
  background-color: var(--gray-700);
  padding: 4px 8px;
  border-radius: 50px;
}

.txnHash {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.txnHashLink {
  color: var(--gray-400);
  text-decoration: none;
  font-size: 14px;
}

.txnHashLink:hover {
  text-decoration: underline;
}

.transactionContainer {
  position: relative; /* Add this to position the fade overlay */
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 140px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Add fade overlay at the top */
  &::before {
    content: "";
    position: fixed;
    top: 1;
    left: 0;
    right: 0;
    height: 40px; /* Adjust height of fade effect as needed */
    background: linear-gradient(
      to bottom,
      var(--gray-900) 0%,
      rgba(17, 17, 17, 0.096) 40%,
      transparent 100%
    );
    pointer-events: none; /* Allows clicking through the overlay */
    z-index: 1;
  }
}

.skeletonChart {
  position: relative;
  margin: 20px;
}

.skeletonChart::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  animation: shimmer 2s infinite;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.05) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.noScrollbar::-webkit-scrollbar {
  display: none;
}

.toggleSwitch {
  position: relative;
  width: 46px;
  height: 24px;
  margin: 0;
}

.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSwitch label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-700);
  transition: all 0.4s ease;
  border-radius: 34px;
}

.toggleSwitch label:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: all 0.4s ease;
  border-radius: 50%;
  box-shadow: 0 2px 4px var(--gray-200);
}

.toggleSwitch input:checked + label {
  background-color: var(--yellow-primary);
}

.toggleSwitch input:checked + label:before {
  transform: translateX(22px);
  background-color: var(--gray-800);
}

.toggleText {
  color: var(--gray-400);
}

/* Add these new skeleton loader styles */
.skeletonSpotlight {
  background: var(--gray-800);
  border-radius: 20px;

  animation: pulse 1.5s infinite;
}

.skeletonSpotlightContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.skeletonLogo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--gray-700);
}

.skeletonText {
  flex: 1;
}

.skeletonLine {
  height: 20px;
  background: var(--gray-700);
  border-radius: 4px;
  margin-bottom: 8px;
  margin-left: 20px;
}

.skeletonLine:last-child {
  width: 50%;
}

.skeletonChip {
  min-width: 150px;
  height: 64px;
  background: var(--gray-800);
  border: 1px solid var(--gray-700);
  border-radius: 20px;
  animation: pulse 1.5s infinite;
}

.skeletonRow {
  display: flex;
  align-items: center;
  padding-left: 8px;
  gap: 0px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.description {
  font-size: 1rem;
  color: var(--gray-200);
}

.readMoreButton {
  background: none;
  border: none;
  color: var(--yellow-primary);
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
  opacity: 0.8;
}

.balanceContainer {
  margin: 20px 0px;
}

.drawerHandle {
  width: 40px;
  height: 4px;
  background: #666;
  border-radius: 2px;
  margin: 0 auto 20px;
}

.drawerContent {
  max-height: 100vh;
}

.tokenHeader {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

.tokenHeader img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.tokenHeader h2 {
  margin: 0;
  font-size: 1.5rem;
}

.tokenHeader p {
  margin: 4px 0 0;
  color: #888;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(54, 54, 54, 0.733);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px); /* For Safari */
  z-index: 999;
}

.tokenDrawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--gray-900);
  border-radius: 20px 20px 0 0;
  padding: 20px 0px;
  z-index: 1000;
  touch-action: none;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.3);
}
