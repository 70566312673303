.main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
  z-index: 10000;

  @media (max-width: 550px) {
    top: 50px;
  }
}

.popUpOverrides {
  @media (max-width: 480px) {
  }
}

.popUp {
  width: 390px;
  height: fit-content;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
  border-radius: 10px;
  border: 1px solid var(--gray-700);
  background: var(--gray-900);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  display: flex;
  flex-direction: column;

  @media (max-width: 550px) {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 0;
    border: none;
    /* Add this line */
    border-bottom-right-radius: 0;
    /* Add this line */
  }

  /*padding-inline: 25px;*/

  & > * {
    padding-inline: 25px;
  }
}

.tokenCardCon {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 1rem;
}

.tokenCard {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .top {
    display: flex;
    align-items: center;
    gap: 10px;

    & > img {
      width: 40px;
      height: auto;
    }

    & > div {
      & > h1 {
        font-size: 18px;
      }

      & > p {
        font-size: 14px;
        color: var(--gray);
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > div {
      & > h1 {
        font-size: 16px;
      }

      & > p {
        font-size: 12px;
        color: var(--gray);
      }
    }

    .profit {
      color: var(--green);
    }

    .loss {
      color: var(--red);
    }
  }
}

.bottomCon {
  padding-inline: 0px;

  & > * {
    padding-inline: 25px;
  }
}

.searchCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  border-top: 1px solid var(--blue-gray);

  & > h1 {
    font-size: 15px;
  }

  & > div {
    width: 155px;
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    border: 1px solid #3466ea;
    border-radius: 10px;
    background: var(--black);
    gap: 10px;
    padding-inline: 14px;
    padding-block: 13px;

    & > img {
      width: 14px;
      height: auto;
    }

    & > * {
      background: var(--black);
      color: var(--white);
      border: none;
      font-size: 12px;
      width: 100px;

      &:focus {
        outline: none;
      }
    }
  }
}

.btnCon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 28px;
  margin-bottom: 30px;
  margin-top: 25px;

  & > * {
    height: 55px;
    width: 55px;
    background: var(--black-bg);
    border: 2px solid #30303060;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      height: 16px;
      width: auto;
    }
  }
}

.balanceCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.4rem;
  margin-top: 1rem;

  & > * {
    color: var(--light-gray);
  }

  & > h1 {
    font-size: 26px;
    color: var(--violet);
    color: var(--white);
    letter-spacing: 1px;
  }
}

.userCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;

  & > p {
    font-size: 17px;
  }

  & > button {
    height: 55px;
    width: fit-content;
    padding-inline: 1.5rem;
    background: var(--black-bg);
    border: none;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & > img {
      height: 16px;
      width: auto;
    }

    & > p {
      font-size: 14px;
      color: var(--white);
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.backBtn {
  position: absolute;
  left: 1rem;
  cursor: pointer;
}

.header h2 {
  margin: 0;
  font-size: 1.5rem;
}

.bell {
  background: none !important;
}

.profileCon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
}

.profileImg {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  padding: 5px;
  background: black;
  box-shadow: 0 0 0px 2px var(--yellow-primary);
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  background: black;
}

.menuOptions {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: var(--black-bg);
  border-radius: 20px;
  margin: 24px;
  border: 1px solid var(--gray-800);
  margin-top: 5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 16px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.menuItem:hover {
  background: rgba(255, 255, 255, 0.1);
}

.menuIcon {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  text-align: center;
}

.menuIcon img {
  width: 100%;
  height: 100%;
}

.menuItem span {
  flex: 1;
  font-size: 16px;
}

.arrowCircle {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--gray-600);
  display: flex;
  align-items: center;
  justify-content: center;
}
