:root {
	--black: #000000;
	--black-bg: #131313;
	--transparent-black: rgba(19, 19, 19, 0.9);

	--white: #ffffff;

	--gray: #a4a4a4;
	--blue-gray: #31323a;
	--light-gray: #686868;
	--dark-gray: #33343d;
	--violet-gray: #73758d;
	--lighter-gray: #eaebef;

	--violet: #8490ff;
	--violet-gray: #555c9f;

	--blue: #4b5ae4;
	--green: #24cc8b;
	--red: #cc384c;
	--lime: #b3e44b;

	--sidebar-width: 280px;
	--announcement-height: 40px;
	--screen-lg: 1080px;

	--gray-900: #000000;
	--gray-800: #202020;
	--gray-700: #3c3c3c;
	--gray-600: #575757;
	--gray-500: #737373;
	--gray-400: #8e8e8e;
	--gray-300: #aaaaaa;
	--gray-200: #c6c6c6;
	--gray-100: #e1e1e1;
	--gray-50: #fdfdfd;
	--primary: #3466ea;
}
